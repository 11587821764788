import axios from 'axios';

const generateText = async (prompt) => {
  try {
    const response = await axios.post('/prompts/generate_text', { prompt });
    return response.data;
  } catch (error) {
    console.error('Error making request to Rails controller:', error.message);
    console.error('Error name:', error.name);
    console.error('Stack trace:', error.stack);
    throw error;
  }
};

const findForms = async (prompt) => {
  try {
    const response = await axios.post('/prompts/find_forms', { prompt });
    return response.data;
  } catch (error) {
    console.error('Error making request to Rails controller:', error.message);
    console.error('Error name:', error.name);
    console.error('Stack trace:', error.stack);
    throw error;
  }
};

const getMetaData = async (name) => {
  try {
    const response = await axios.get(`/prompts/${name}/get_meta_data`);
    return response.data;
  } catch (error) {
    console.error('Error making request to Rails controller:', error);
    throw error;
  }
};

const summarizeFile = async (file) => {
  try {
    const formData = new FormData();
    formData.append('file', file);

    const response = await axios.post('/paperworkers/summarize_file', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error making request to Rails controller:', error);
    throw error;
  }
};

const uploadFiles = async (prompt, files) => {
  let formData = new FormData();

  // Append the selected files
  files.forEach((file, index) => {
    formData.append(`uploads[${index}]`, file);
  });

  // Append other data
  formData.append('prompt', prompt);

  try {
    const response = await axios.post('/prompts/generate_text', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error making request to Rails controller:', error);
    throw error;
  }
};

export { generateText, getMetaData, summarizeFile, findForms, uploadFiles};
