import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../../assets/stylesheets/landingPage.scss';
import { ContactContainer } from './RandomComponents';
import mountains from '../../assets/images/mountains2.jpg';
import headshot from '../../assets/images/headshot.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStroopwafel, faUser, faTools, faProjectDiagram, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin, faUpwork, faGithub, faInstagram } from '@fortawesome/free-brands-svg-icons';
import OpenAIChatbot from './openAiChatbot';

const Modal = ({ showModal, closeModal }) => {
  if (!showModal) return null;

  return (
    <div className="modal-overlay" onClick={closeModal}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className='close-modal-button-container'>
          <button className='close-modal-button' onClick={closeModal}>Close</button>
        </div>
        <OpenAIChatbot />
      </div>
    </div>
  );
};

const LandingPage = () => {
  const [currentSection, setCurrentSection] = useState(0);
  const [darkMode, setDarkMode] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const sections = [
    <div className="section about-me-section">
      <h3 className="section-title">About Me</h3>
      <p>I’m a seasoned software engineer with expertise in full-stack development, specializing in Ruby on Rails, 
        React, and AI prompt programming. My work is characterized by a commitment to delivering high-quality, 
        scalable solutions that solve complex problems and drive innovation. Over the years, 
        I’ve led teams, architected robust systems, and integrated cutting-edge AI technologies into projects, 
        all while maintaining best practices and a strong focus on client needs.
      </p>

      <p>
        My passion lies in creating software that not only meets but exceeds expectations. 
        I take pride in my ability to translate intricate requirements into efficient, 
        reliable code and in my dedication to continuous improvement. Whether you’re looking for someone to lead a project, 
        refine an existing system, or push the boundaries of what’s possible with AI, I’m here to help make it happen.
      </p>
    </div>,
    <div className="section skills-section">
      <h3 className="section-title">Skills</h3>
      <div className="skills-container">
        <div className="skill">
          <span className="skill-name">React</span>
          <div className="skill-bar">
            <div className="skill-bar-fill" style={{ width: '100%' }}></div>
          </div>
        </div>
        <div className="skill">
          <span className="skill-name">JavaScript</span>
          <div className="skill-bar">
            <div className="skill-bar-fill" style={{ width: '100%' }}></div>
          </div>
        </div>
        <div className="skill">
          <span className="skill-name">Ruby on Rails</span>
          <div className="skill-bar">
            <div className="skill-bar-fill" style={{ width: '100%' }}></div>
          </div>
        </div>
        <div className="skill">
          <span className="skill-name">API Development</span>
          <div className="skill-bar">
            <div className="skill-bar-fill" style={{ width: '100%' }}></div>
          </div>
        </div>
        <div className="skill">
          <span className="skill-name">Agile Development</span>
          <div className="skill-bar">
            <div className="skill-bar-fill" style={{ width: '100%' }}></div>
          </div>
        </div>
        <div className="skill">
          <span className="skill-name">AI Prompting</span>
          <div className="skill-bar">
            <div className="skill-bar-fill" style={{ width: '100%' }}></div>
          </div>
        </div>
      </div>
    </div>,
    // <div className="section portfolio-section">
    //   <h3 className="section-title">Projects</h3>
    //   <div className="portfolio-item">
    //     <h3>Project 1</h3>
    //     <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus bibendum, felis non tincidunt egestas, urna leo commodo massa.</p>
    //   </div>
    //   <div className="portfolio-item">
    //     <h3>Project 2</h3>
    //     <p>Donec at volutpat erat. Nullam ut sapien in nisl ultrices volutpat et nec nisi.</p>
    //   </div>
    //   <div className="portfolio-item">
    //     <h3>Project 3</h3>
    //     <p>Etiam ut orci nec sapien ullamcorper ultrices. Fusce sit amet augue convallis, volutpat ex non, tincidunt felis.</p>
    //   </div>
    //   <div className="portfolio-item">
    //     <h3>Project 4</h3>
    //     <p>Etiam ut orci nec sapien ullamcorper ultrices. Fusce sit amet augue convallis, volutpat ex non, tincidunt felis.</p>
    //   </div>
    // </div>,
    <div className="section contact-section">
      <h2 className="section-title">Contact</h2>
        <ContactContainer />
    </div>
  ];

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setCurrentSection((prevSection) => (prevSection + 1) % sections.length);
  //   }, 20000); // Change section every 20 seconds

  //   return () => clearInterval(interval); // Cleanup interval on component unmount
  // }, [sections.length]);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
    document.body.classList.toggle('dark-mode', !darkMode);
  };

  return (
    <div className={`main-container ${darkMode ? 'dark' : ''}`}>
      <Modal showModal={showModal} closeModal={closeModal} />
      <header className="header">
        <nav className="top-links-container">
          <div className="link-container">
            <a className="nav-link" href="https://www.linkedin.com/in/PaulStorberg" target="_blank">
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
          </div>
          <div className="link-container">
            <a className="nav-link" href="https://www.upwork.com/o/profiles/users/_~010d7a419385669325/" target="_blank">
              <FontAwesomeIcon icon={faUpwork} />
            </a>
          </div>
          <div className="link-container">
            <a className="nav-link" href="https://github.com/PaulStorberg" target="_blank">
              <FontAwesomeIcon icon={faGithub} />
            </a>
          </div>
          <div className="link-container">
            <a className="nav-link" href="https://www.instagram.com/paulstorberg/" target="_blank">
              <FontAwesomeIcon icon={faInstagram} />
            </a>
          </div>
          {/* <div className='link-container'>
            <div className="dark-mode-toggle">
              <button onClick={toggleDarkMode}>
                {darkMode ? '🌙' : '☀️'}
              </button>
            </div>
          </div> */}
        </nav>
      </header>

      <div className="hero-container" style={{ backgroundImage: `url(${mountains})` }}>
        <div className="hero-text">
          <h2 className="hero-name">Paul Storberg</h2>
          <h3 className="hero-title">Software Engineer</h3>
          <button className="explore-button" onClick={openModal}>
            Chat with PaulBot
          </button>
        </div>
        <div className="hero-image-box">
          <img src={headshot} alt="Headshot Image" />
        </div>
      </div>

      <div className="buttons-container">
        <FontAwesomeIcon 
            className={`section-toggle ${currentSection === 0 ? 'active' : ''}`} 
            onClick={() => setCurrentSection(0)} 
            icon={faUser} 
        />
        <FontAwesomeIcon 
            className={`section-toggle ${currentSection === 1 ? 'active' : ''}`} 
            onClick={() => setCurrentSection(1)} 
            icon={faTools} 
        />
        {/* <FontAwesomeIcon 
            className={`section-toggle ${currentSection === 2 ? 'active' : ''}`} 
            onClick={() => setCurrentSection(2)} 
            icon={faProjectDiagram} 
        /> */}
        <FontAwesomeIcon 
            className={`section-toggle ${currentSection === 2 ? 'active' : ''}`} 
            onClick={() => setCurrentSection(2)} 
            icon={faEnvelope} 
        />
      </div>

      {sections[currentSection]}
    </div>
  );
};

export default LandingPage;
