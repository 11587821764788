import React from 'react';
import Countdown from './CountDown';
import DailyMessage from './DailyMessage';
import '../../assets/stylesheets/AmandasPage.css';

class AmandasPage extends React.Component {
  calculateDaysSinceStartDate( startDate ) {
    const now = new Date();
    const timeDiff = now - startDate; // Difference in milliseconds
    const daysSinceStartDate = Math.floor(timeDiff / (1000 * 60 * 60 * 24)); // Convert milliseconds to days
    return daysSinceStartDate;
  }

  getYearAndNextAgeForNextBirthday(birthYear, month, day) {
    const now = new Date();
    const thisYearBirthday = new Date(now.getFullYear(), month - 1, day);
    let nextBirthdayYear;
    if (now > thisYearBirthday) {
      // If this year's birthday has already passed, return next year
      nextBirthdayYear = now.getFullYear() + 1;
    } else {
      // Otherwise, return this year
      nextBirthdayYear = now.getFullYear();
    }
    const nextAge = nextBirthdayYear - birthYear;
    return { year: nextBirthdayYear, age: nextAge };
  }

  render() {
    const yourBirthday = this.getYearAndNextAgeForNextBirthday(1991, 4, 2); // replace 1990 with your birth year
    const myBirthday = this.getYearAndNextAgeForNextBirthday(1990, 11, 1); // replace 1992 with my birth year
    const relationshipStartDate = new Date('2022-01-27');
    const converationStartDate = new Date('2021-08-24');

    return (
      <div className="amandas-page-container">
        <div className="countdown-container">
            <h1 className="greeting-text">Hello Love! 💖</h1>
            <h3 className="love-note-text">I love You</h3>

            <DailyMessage startDate={relationshipStartDate} />

            <h5 className="countdown-title">{this.calculateDaysSinceStartDate(converationStartDate)} Days since I slid into your DMs. 😉</h5>
            <h5 className="countdown-title">{this.calculateDaysSinceStartDate(relationshipStartDate)} Days since we made this offical! 👫🏻</h5>
            
            <Countdown date={`${yourBirthday.year}-04-02T00:00:00`} until={` Until You're ${yourBirthday.age}! 🎂`} className="your-birthday-countdown" />
            <Countdown date={`${myBirthday.year}-11-01T00:00:00`} until={` Until I'm ${myBirthday.age}! 🎉`} className="my-birthday-countdown" />
            <Countdown date={"2025-10-01T00:00:00"} until={" Until Our Wedding! 💍"} className="wedding-countdown" />
        </div>
      </div>
    );
  }
}

export default AmandasPage;
