import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import OpenAIChatbot from '../components/openAiChatbot.jsx';
import FormFinder from '../components/FormFinder.jsx';
import LandingPage from '../components/landingPage.jsx';
import AmandasPage from '../components/AmandasPage.jsx';
import PaperWorker from '../components/PaperWorker.jsx';
import SignIn from '../components/SignIn.jsx';
import "@hotwired/turbo-rails"
import "controllers"
import ReactOnRails from 'react-on-rails';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStroopwafel } from '@fortawesome/free-solid-svg-icons';
import '../../assets/stylesheets/application.css';
import axios from 'axios';
import '@fortawesome/fontawesome-svg-core/styles.css';

// Set CSRF token for Axios
const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
axios.defaults.headers.common['X-CSRF-Token'] = csrfToken;

ReactOnRails.register({
  OpenAIChatbot,
  FormFinder,
  PaperWorker,
  LandingPage,
  AmandasPage,
  SignIn
});

document.addEventListener('DOMContentLoaded', () => {
    const root = document.body.appendChild(document.createElement('div'));
    createRoot(root).render(
      <Router>
        <Routes>
          <Route path="/paperworker" element={ <PaperWorker /> } />
          <Route path="/form_finder" element={<FormFinder />} />
          <Route path="/" element={<LandingPage />} />
          <Route path="/amandas_page" element={<AmandasPage />} />
          <Route path="/users/sign_in" element={<SignIn />} />
        </Routes>
      </Router>
    );
  });

library.add(faStroopwafel);
