import React from 'react';

const QuestionsDisplay = ({ summary }) => {
  console.log(summary);

  let summaryObject;
  if (summary) {
    try {
      summaryObject = JSON.parse(summary);
    } catch (error) {
      console.error('Error parsing summary:', error);
    }
  }

  const questions = summaryObject && summaryObject.summary && summaryObject.summary.questions && Object.keys(summaryObject.summary.questions);

  return (
    <div>
        <div>DATA HERE:</div>
        {questions && questions.map((question, index) => (
          <div key={index}>
            <p>{question}</p>
          </div>
        ))}
    </div>
  );
};

export default QuestionsDisplay;
