import React from "react";

// This file is for random components that don't fit anywhere else

export function ContactContainer() {
  return (
    <div className="contact-container">
        <form className="form-container" action="https://formspree.io/paul@storberg.net" method="POST" >
        <input className="form-input" type="text" name="name" placeholder="Name" />
        <input className="form-input" type="text" name="email" placeholder="Email" />
        <textarea className="form-input" type="text" rows="3" name="outline" placeholder="Questions..." />
        <button className="form-submit" action="submit">Send</button>
        </form>
    </div>
  );
}