import React, { useState } from 'react';
import axios from 'axios';

const SignIn = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);

  const getCsrfToken = () => {
    const meta = document.querySelector('meta[name="csrf-token"]');
    return meta && meta.getAttribute('content');
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const csrfToken = getCsrfToken();
      const response = await axios.post('/users/sign_in', {
        user: {
          email: email,
          password: password,
          remember_me: rememberMe ? '1' : '0'
        }
      }, {
        headers: {
          'X-CSRF-Token': csrfToken,
          'Accept': 'application/json'
        }
      });
      // Handle successful response
      if (response.data.success) {
        window.location.href = response.data.redirect_url;
      }
    } catch (error) {
      // Handle error response
      console.error(error);
    }
  };

  return (
    <div className="main-container">
      <h2 className="jumbotron-name">Log in</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-container">
          <h3 className="form-title">Email</h3>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            autoFocus
            autoComplete="email"
            className="form-input"
          />
        </div>

        <div className="form-container">
          <h3 className="form-title">Password</h3>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            autoComplete="current-password"
            className="form-input"
          />
        </div>

        <div className="form-container">
          <input
            type="checkbox"
            checked={rememberMe}
            onChange={(e) => setRememberMe(e.target.checked)}
          />
          <h3 className="form-title">Remember me</h3>
        </div>

        <div className="form-container">
          <button type="submit" className="form-submit">Log in</button>
        </div>
      </form>
      <div>
        <a href="/users/password/new">Forgot your password?</a><br />
        <a href="/users/sign_up">Sign up</a>
      </div>
    </div>
  );
};

export default SignIn;
