import React, { useState } from 'react';
import { summarizeFile } from '../services/openaiAPI';
import QuestionsDisplay from './QuestionsDisplay';
import ReactLoading from 'react-loading';

const PaperWorker = () => {
  const [file, setFile] = useState(null);
  const [summary, setSummary] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    if (file) {
      setIsLoading(true);
      try {
        const response = await summarizeFile(file);
        setSummary(JSON.stringify(response, null, 2));
      } catch (error) {
        console.error('Error summarizing file:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <input type="file" onChange={handleFileChange} />
      <button type="submit">Submit File</button>
      {isLoading ? <ReactLoading type={"bars"} color={"#000000"} /> : <QuestionsDisplay summary={summary} />}
    </form>
  );
};

export default PaperWorker;
