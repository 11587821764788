import React, { useState, useEffect } from 'react';

const DailyMessage = ({ startDate }) => {
  const messages = [
    "I like your Butt and I cannot lie! 🍑",
    "I cant wait to spend the rest of my life with you! 💍",
    "Cant wait to cuddle with you! 🥰",
    "Its hard not being with you 24/7! 😔",
    "Butt Stuff? hehehe 🍑",
    "You're the love of my life. 💘",
    "You're my happy ending. 📖",
    "Being with you is my favorite journey. 🚀",
    "I love you more than words can express. 💌",
    "You're my favorite notification. 📲",
    "You're my favorite hello and hardest goodbye. 👋",
    "I love you to the moon and back. 🌙",
    "You're my favorite person. 👫",
    "You're my favorite everything. 🌎",
    "You're my favorite reason to lose sleep. 😴😉",
    "You're my favorite distraction. 🤪",
    "You're my favorite kind of trouble. 😈",
    "You're my favorite kind of fun. 🎉",
    "You're my favorite kind of adventure. 🌄",
  ];

  function calculateIndex(startDate) {
    const date = (startDate instanceof Date) ? startDate : new Date(startDate);
    const today = new Date();
    const diffTime = Math.abs(today - date);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays % messages.length;
  }

  const [currentMessage, setCurrentMessage] = useState('');

  useEffect(() => {
    const index = calculateIndex(startDate);
    setCurrentMessage(messages[index]);
  }, [startDate]);

  return (
    <h6 className="love-note-text">{currentMessage}</h6>
  );
};

export default DailyMessage;
