import React from 'react';
import DOMPurify from 'dompurify';

function FormatResponse(responseObj) {
    console.log('responseObj structure:', responseObj);

    // Check for responseObj validity
    if (!responseObj || !responseObj.item) {
        return <p>No response available. Please contact support for assistance.</p>;
    }

    // Handle case where responseObj.item contains an 'error' field
    if (responseObj.item.error) {
        return (
        <div>
            <p>Error: {responseObj.item.error}</p>
        </div>
        );
    }

    // Handle case where responseObj.item has a clarification field
    if (responseObj.item.clarification) {
        return <p>{responseObj.item.clarification}</p>;
    }

    // Check if responseObj.item contains a 'documents' field that is an array
    if (responseObj.item.documents && Array.isArray(responseObj.item.documents)) {
        const documents = responseObj.item.documents;
        return documents.length > 0 ? (
            <div>
                {documents.map((doc, index) => (
                    <div key={index}>
                        <p>{doc.brief_description}</p>
                        <p>{doc.file_name}</p>
                        <a href={doc.url} target="_blank" rel="noopener noreferrer">Follow Link</a>
                    </div>
                ))}
            </div>
        ) : <p>No documents found. Please try again.</p>;
    }

    // Handle case where responseObj.item is directly an array
    if (Array.isArray(responseObj.item)) {
        const items = responseObj.item;
        return items.length > 0 ? (
        <div>
            {items.map((form, index) => (
            <div key={index}>
                <p>{form.brief_description}</p>
                <p>{form.file_name}</p>
                <a href={form.url} target="_blank" rel="noopener noreferrer">Follow Link</a>
            </div>
            ))}
        </div>
        ) : <p>No data found. Please try again.</p>;
    }

    // Check if responseObj.item directly contains the fields 'brief_description', 'file_name', and 'url'
    if (responseObj.item.brief_description && responseObj.item.file_name && responseObj.item.url) {
        return (
            <div>
                <p>{responseObj.item.brief_description}</p>
                <p>{responseObj.item.file_name}</p>
                <a href={responseObj.item.url} target="_blank" rel="noopener noreferrer">Follow Link</a>
            </div>
        );
    }

    // Handle case where responseObj.item contains a 'files' field
    if (responseObj.item.files && Array.isArray(responseObj.item.files)) {
        const files = responseObj.item.files;
        return files.length > 0 ? (
        <div>
            {files.map((file, index) => (
            <div key={index}>
                <p>{file.brief_description}</p>
                <p>{file.file_name}</p>
                <a href={file.url} target="_blank" rel="noopener noreferrer">Follow Link</a>
            </div>
            ))}
        </div>
        ) : <p>No files found. Please try again.</p>;
    }

    // NEW: Handle case where responseObj.item contains an 'OUTPUT' field
    if (responseObj.item.OUTPUT && Array.isArray(responseObj.item.OUTPUT)) {
        const outputItems = responseObj.item.OUTPUT;
        return outputItems.length > 0 ? (
        <div>
            {outputItems.map((item, index) => (
            <div key={index}>
                <p>{item.brief_description}</p>
                <p>{item.file_name}</p>
                <a href={item.url} target="_blank" rel="noopener noreferrer">Follow Link</a>
            </div>
            ))}
        </div>
        ) : <p>No output data found. Please try again.</p>;
    }

    // Check if responseObj.item contains a 'response' field with a string message
    if (responseObj.item.response && typeof responseObj.item.response === 'string') {
        return (
            <div>
                <p>{responseObj.item.response}</p>
            </div>
        );
    }

    // Function to convert URLs in text to clickable links
    const convertURLsToLinks = (text) => {
        const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/ig;
        return text.replace(urlRegex, (url) => `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`);
    };

    // Handle different responseObj.item types
    if (typeof responseObj.item === 'string') {
        // Sanitize the string
        const sanitizedString = DOMPurify.sanitize(responseObj.item);
        // Convert URLs in the sanitized string to clickable links
        const stringWithLinks = convertURLsToLinks(sanitizedString);

        return (
            <div dangerouslySetInnerHTML={{ __html: stringWithLinks }} />
        );
    } else if (responseObj.item && Array.isArray(responseObj.item.response)) {
        const { response } = responseObj.item;
        return response.length > 0 ? (
        <div>
            {response.map((form, index) => (
            <div key={index}>
                <p>{form.brief_description}</p>
                <p>{form.file_name}</p>
                <a href={form.url} target="_blank" rel="noopener noreferrer">Follow Link</a>
            </div>
            ))}
        </div>
        ) : <p>No data found. Please try again.</p>;
    } else {
        return <p>Invalid response format. Please contact support for assistance.</p>;
    }
}

export default FormatResponse;
